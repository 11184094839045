<template>
  <div class="a-text-input a-input">
    <label class="a-input__label" :for="id">
      <input
        type="tel"
        :class="{ 'is-filled': value, 'is-invalid': error }"
        :name="name"
        :id="id"
        :required="required"
        @blur="onBlur"
        @input="onInput"
        v-maska="mask"
        v-model="value"
      />
      <span class="a-input__label__text">{{ label }}</span>
      <span v-if="error && typeof error === 'string'" class="a-input__error">{{
        error
      }}</span>
    </label>
  </div>
</template>

<script>
import InputMixin from "@/mixins/InputMixin";

export default {
  name: "ATextInput",
  mixins: [InputMixin],
  props: {
    mask: {
      default: null,
      required: false,
      type: String
    }
  }
};
</script>
