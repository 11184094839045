<template>
  <img
    class="a-logo-amgen"
    :src="require(`@/assets/${fileName}`)"
    alt="Logo Amgen"
  />
</template>

<script>
import { ref } from "vue";

export default {
  name: "ALogoAmgen",
  props: {
    white: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  setup(props) {
    // const fileName = ref("amgen");

    // if (props.white) {
    //   fileName.value += "-white";
    // }

    // return {
    //   fileName
    // };
  var fileName
   if (props.white) {
    fileName = ref("amgen2-white2.svg");
   }


   if (!props.white) {
    fileName = ref("amgen2.png");
   }


   return {
     fileName
   };

  }
};
</script>
