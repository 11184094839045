<template>
  <div class="a-select-input a-input">
    <label class="a-input__label" :for="id" ref="labelRef" tabindex="4">
      <input
        type="hidden"
        :class="{ 'is-filled': value.value, 'is-invalid': error }"
        :name="name"
        :id="id"
        readonly
        @change="onChange"
        :required="required"
        v-model="value.value"
      />
      <div
        v-html="value.label"
        :class="{ 'is-filled': value.value, 'is-invalid': error }"
        class="has-text-left has-text-weight-bold input is-lowercase is-size-7"
      ></div>
      <span class="a-input__label__text">{{ label }}</span>
      <span v-if="error && typeof error === 'string'" class="a-input__error">{{
        error
      }}</span>
      <ul class="a-select-input__options has-text-left is-lowercase">
        <li
          :class="option.value"
          class="pb-2 pt-2"
          v-html="option.label"
          v-for="option in options"
          :key="option.value"
          @click.prevent="() => onOptionChange(option)"
        />
      </ul>
    </label>
  </div>
</template>

<script>
import InputMixin from "@/mixins/InputMixin";
import { ref } from "vue-demi";

export default {
  name: "SelectInput",
  mixins: [InputMixin],
  props: {
    onChange: {
      required: true,
      type: Function
    },
    options: {
      required: true,
      type: Array
    }
  },
  setup(props) {
    const labelRef = ref(null);

    function onOptionChange(option) {
      props.onChange(option);
      labelRef.value.blur();
    }

    return {
      labelRef,
      onOptionChange
    };
  }
};
</script>

<style lang="scss">
$a-select-input: ".a-select-input";

#{$a-select-input} {
  label {
    &::after,
    &::before {
      @extend %position-absolute;
      @extend %pseudo-el;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      background-image: url("../../assets/arrow-down.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      height: 10px;
      right: 12px;
      top: calc(50% + 2px);
      width: 16px;
    }

    &::before {
      background-color: $denim;
      border-radius: 3px;
      cursor: pointer;
      height: 32px;
      right: 4px;
      width: 32px;
    }

    &:active,
    &:focus-within {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }

      #{$a-select-input} {
        &__options {
          display: block;
        }
      }
    }

    .input {
      padding: 1.25rem calc(1.25rem + 32px) 1.25rem 1.25rem;

      &.is-filled {
        padding: 0.8rem calc(0.8rem + 32px) 0.8rem 0.8rem;
      }
    }

    .a-input__error {
      right: 2.8rem;
    }
  }

  &__options {
    @extend %position-absolute;
    background-color: $white;
    border: 1px solid $gallery;
    display: none;
    padding: 0 0.8rem;
    width: 100%;
    z-index: 4;

    li {
      cursor: pointer;

      &:hover {
        color: $lochmara;
      }

      &:not(:nth-last-of-type(1)) {
        border-bottom: 1px solid $gallery;
      }
    }
  }
}
</style>
