<template>
  <MTabs class="m-patient-form-tabs" :activeTab="defaultTab" :tabs="tabs" />
</template>

<script>
import MTabs from "@/components/molecules/Tabs.vue";

export default {
  name: "MPatientFormTabs",
  components: {
    MTabs
  },
  props: {
    defaultTab: {
      type: String,
      required: true
    },
    onChangeTab: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const tabs = [
      {
        id: "sou-participante",
        title: "Sou Participante",
        action: () => props.onChangeTab("sou-participante")
      },
      {
        id: "faca-parte",
        title: "Faça Parte",
        action: () => props.onChangeTab("faca-parte")
      },
    ];

    return {
      tabs
    };
  }
};
</script>

<style lang="scss">
.m-patient-form-tabs {
  background-color: #70CDED;
  border-radius: 60px;
  max-width: 368px;
  overflow: hidden;

  a {
    color: white;
    display: block;
    font-size: pxToEm(14px);
    font-weight: 500;
    opacity: 1;
    padding: 1rem;
    text-decoration: none;
    width: 100%;
  }

  li {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    &.is-active {
      a {
        color: #70CDED;
        opacity: 1;
      }
    }
    
  }

  .m-tabs {
    
    &__spot {
      background-color: #fff;
      border-radius: 60px;
      box-shadow: 0 6px 12px#00000053;
      height: calc(100% - 8px);
      left: 4px;
      position: absolute;
      top: 50%;
      transition: left 0.5s ease-in-out;
      transform: translateY(-50%);
      
    }
  }
}
</style>
