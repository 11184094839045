<template>
  <MSlider class="o-medicines-slider" :settings="sliderSettings">
    <template v-slot:prevArrow>
      <img src="../../assets/arrow.svg" alt="Anterior" />
    </template>
    <template v-slot:nextArrow>
      <img src="../../assets/arrow.svg" alt="Próximo" />
    </template>
    <div class="o-medicines-slider__item osteoporose">
      <div class="o-medicines-slider__item__wrapper" style="border-radius: 0px;">
        <a
          :href="`${host}/liberdade`"
          title="Programa BemPerto® da sua liberdade"
        >
          <!-- <img src="../../assets/osteoporose.svg" alt="Osteoporose" /> -->
          <!-- <h2 class="has-text-weight-light is-lowercase">Ossos</h2> -->
          <!--  <AButton>Conheça</AButton> -->
        </a>
      </div>
      <div class="o-medicines-slider__item__wrapper is-hidden-desktop" style="border-radius: 0px;">
        <a
          :href="`${host}/liberdade`"
          title="Programa BemPerto® da sua liberdade"
        >
          <img src="../../assets/bones-swipe.svg" alt="Osteoporose" />
          <h2>Ossos</h2>
          <!--  <AButton>Conheça</AButton> -->
        </a>
      </div>
    </div>
    <div class="o-medicines-slider__item cancer-colorretal">
      <div class="o-medicines-slider__item__wrapper" style="border-radius: 0px;">
        <a :href="`${host}/amanha`" title="Programa BemPerto® do seu amanhã">
          <!-- <img
            src="../../assets/cancer-colorretal.svg"
            alt="Câncer Colorretal"
          /> -->
          <!-- <h2 class="has-text-weight-light is-lowercase">Intestino</h2> -->
          <!-- <AButton>Conheça</AButton> -->
        </a>
      </div>
      <div class="o-medicines-slider__item__wrapper" style="border-radius: 0px;">
        <a :href="`${host}/amanha`" title="Programa BemPerto® do seu amanhã">
          <img src="../../assets/intestine-swipe.svg" alt="Câncer Colorretal" />
          <h2>Intestino</h2>
          <!-- <AButton>Conheça</AButton> -->
        </a>
      </div>
    </div>
    <div class="o-medicines-slider__item lla">
      <div class="o-medicines-slider__item__wrapper" style="border-radius: 0px;">
        <a
          :href="`${host}/dequemvoceama`"
          title="Programa BemPerto® de quem você ama"
        >
          <!-- <img
            src="../../assets/leucemia-linfoblastica-aguda.svg"
            alt="Leucemia Linfoblástica Aguda"
          /> -->
          <!-- <h2 class="has-text-weight-light is-lowercase">
            Medula óssea
          </h2> -->
          <!-- <AButton>Conheça</AButton> -->
        </a>
      </div>
      <div class="o-medicines-slider__item__wrapper" style="border-radius: 0px;">
        <a
          :href="`${host}/dequemvoceama`"
          title="Programa BemPerto® de quem você ama"
        >
          <img
            src="../../assets/drops-swipe.svg"
            alt="Leucemia Linfoblástica Aguda"
          />
          <h2> Medula óssea</h2>
          <!-- <AButton>Conheça</AButton> -->
        </a>
      </div>
    </div>
    <div class="o-medicines-slider__item amgevita">
      <div class="o-medicines-slider__item__wrapper" style="border-radius: 0px;">
        <a
          :href="`${host}/bemestar`"
          title="Programa BemPerto® do seu bem-estar"
        >
          <!-- <img src="../../assets/doenca-autoimune.svg" alt="Doença Autoimune" /> -->
          <!-- <h2 class="has-text-weight-light is-lowercase">
            Autoimune
          </h2> -->
          <!-- <AButton>Conheça</AButton> -->
        </a>
      </div>
      <div class="o-medicines-slider__item__wrapper" style="border-radius: 0px;">
        <a
          :href="`${host}/bemestar`"
          title="Programa BemPerto® do seu bem-estar"
        >
          <img
            class="img-swipe-autoimune"
            src="../../assets/doenca-autoimune-swipe.svg"
            alt="Doença Autoimune"
          />
          <h2>Autoimune</h2>
          <!-- <AButton>Conheça</AButton> -->
        </a>
      </div>
    </div>
  </MSlider>
</template>

<script>
// import AButton from "@/components/atoms/Button.vue";
import MSlider from "@/components/molecules/Slider.vue";

export default {
  components: {
    // AButton,
    MSlider
  },
  name: "OMedicinesSlider",
  setup() {
    let host = `//${document.location.hostname}`;
    const isLocalhost = /localhost/g.test(host);

    if (isLocalhost) {
      host += ":8000";
    }

    const sliderSettings = {
      infinite: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            centerPadding: "40px",
            slidesToScroll: 2,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1023,
          settings: {
            autoplay: true,
            autoplaySpeed: 5000,
            centerMode: false,
            centerPadding: "40px",
            dots: true,
            pauseOnHover: true,
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1215,
          settings: {
            autoplay: true,
            autoplaySpeed: 5000,
            centerMode: false,
            centerPadding: "40px",
            dots: true,
            pauseOnHover: true,
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1407,
          settings: {
            autoplay: true,
            autoplaySpeed: 5000,
            centerMode: false,
            centerPadding: "40px",
            dots: true,
            pauseOnHover: true,
            slidesToShow: 5
          }
        }
      ]
    };

    return {
      host,
      sliderSettings
    };
  }
};
</script>

<style lang="scss">
$o-medicines-slider: ".o-medicines-slider";

#{$o-medicines-slider} {
  .o-slider__controls {
    height: calc(100% - 4px);
  }

  .osteoporose {
    background-color: #b576c0;

    .a-button {
      color: #906098;
    }
  }

  .cancer-colorretal {
    background-color: #70bb71;

    .a-button {
      color: #70bb71;
    }
  }

  .lla {
    background-color: #fa968a;

    .a-button {
      color: #fa968a;
    }
  }

  .amgevita {
    background-color: #ea582f;

    .a-button {
      color: #ea582f;
    }

    img.img-swipe-autoimune {
      top: 20px !important;
    }
  }

  &__item {
    overflow: hidden;
    padding-top: 65%;
    position: relative;

    &::before {
      @extend %pseudo-el;
      @extend %position-absolute;
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      display: block;
      height: 200%;
      opacity: 0.3;
      right: -100%;
      top: -100%;
      width: 200%;
    }

    &__wrapper {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 7px;

      &:nth-of-type(1) {
        display: none;
      }

      a {
        @extend %position-absolute;
        @extend %zero-position;
        display: block;
        height: 100%;
        width: 100%;

        .a-button {
          @extend %position-absolute;
          background-color: $white;
          bottom: 10px;
          box-shadow: 0 2px 2px #00000015;
          font-size: pxToEm(10px);
          min-width: 100px;
          padding: 0.4rem !important;
          right: 12px;
          width: 100px;
        }
      }
    }

    img {
      @extend %position-absolute;
      left: 0;
      top: 30px;
      width: 100%;
    }

    h2 {
      @extend %position-absolute;
      bottom: 12px;
      color: $white;
      font-weight: 800;
      left: 12px;
      text-transform: capitalize !important;
      width: 50%;
    }
  }

  .slick-dots {
    @extend %position-absolute;
    bottom: 22px;
    left: 0;
    text-align: center;
    width: 100%;

    li {
      display: inline-block;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      vertical-align: top;

      button {
        background: none;
        border-radius: 50%;
        border: 1px solid $white;
        cursor: pointer;
        height: 16px;
        outline: none;
        text-indent: -9999px;
        width: 16px;
      }

      &.slick-active {
        button {
          background-color: $white;
        }
      }
    }
  }
}

@include media(">=tablet") {
  #{$o-medicines-slider} {
    &__item {
      padding-top: 60%;
    }
  }
}

@include media(">=desktop") {
  #{$o-medicines-slider} {
    .slick-list,
    .slick-track {
      padding-bottom: 2rem !important;
    }

    .o-slider__controls {
      height: calc(100% - 56px);

      &__prev,
      &__next {
        position: absolute;
        width: 30px;
      }

      &__prev {
        left: 15px;
      }

      &__next {
        right: 15px;
      }
    }

    .osteoporose {
      #{$o-medicines-slider} {
        &__item {
          &__wrapper {
            background-image: url("../../assets/oesteoporose2.png");
          }
        }
      }
    }

    .cancer-colorretal {
      #{$o-medicines-slider} {
        &__item {
          &__wrapper {
            background-image: url("../../assets/colorretal2.png");
          }
        }
      }
    }

    .lla {
      #{$o-medicines-slider} {
        &__item {
          &__wrapper {
            background-image: url("../../assets/leucemia.png");
          }
        }
      }
    }

    .amgevita {
      #{$o-medicines-slider} {
        &__item {
          &__wrapper {
            background-image: url("../../assets/autoimune2.png");
          }
        }
      }
    }

    &__item {
      background-color: transparent !important;
      overflow: visible;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 0;

      &::before {
        display: none;
      }

      &__wrapper {
        box-shadow: 0 10px 20px #00000030;
        cursor: pointer;
        padding-top: 64%;

        &:nth-of-type(1) {
          display: block;
        }

        &:nth-of-type(2) {
          display: none;
        }

        a {
          .a-button {
            bottom: 16px;
            right: 24px;
          }
        }

        h2,
        img {
          position: absolute;
          left: 24px;
        }

        h2 {
          bottom: 16px;
          color: $white;
          font-size: pxToEm(20px);
          padding-right: 16px;
        }

        img {
          top: 18px;
          width: auto;
        }
      }
    }
  }
}

.o-medicines-slider .osteoporose {
    background-color: #807BC3;
}

.o-medicines-slider .cancer-colorretal {
    background-color: #a498f3;
}

.o-medicines-slider .lla {
    background-color: #78bbef;
}

.o-medicines-slider .amgevita {
    background-color: #54d6fc;
}
</style>
