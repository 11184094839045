<template>
  <MSlider class="o-news-slider" :settings="{ initialSlide: 1 }">
    <template v-slot:prevArrow>
      <img class="o-news-slider__controls--mobile" src="../../assets/arrow.svg" alt="Anterior" />
    </template>
    <template v-slot:nextArrow>
      <img class="o-news-slider__controls--mobile" src="../../assets/arrow.svg" alt="Próximo" />
    </template>
    <div class="o-news-slider__item">
      <!-- <img
        class="o-news-slider__icon"
        src="../../assets/doenca-autoimune.svg"
        alt="Doença Autoimune"
        style="width: 40px;"
      /> -->
      <div class="o-news-slider__wrapper p-6">
        <div class="o-news-slider__content">
          <p class="mb-4">
            O que são medicamentos biossimilares?
          </p>
          <AButton class="p-2 botao-leiamais" component="external"
            title="Leia mais sobre: O que são medicamentos biossimilares?" :href="`${host}/bemestar/biossimilares`">
            Leia mais...
          </AButton>
        </div>
      </div>
    </div>
    <div class="o-news-slider__item">
      <!-- <img
        class="o-news-slider__icon"
        src="../../assets/osteoporose.svg"
        alt="Osteoporose"
      /> -->
      <div class="o-news-slider__wrapper p-6">
        <div class="o-news-slider__content">
          <p class="mb-4">
            Você sabia que 10 milhões de brasileiros sofrem de osteoporose?<sup class="is-size-7">1</sup>
          </p>
          <AButton class="p-2 botao-leiamais" component="external"
            title="Leia mais sobre: Você sabia que 10 milhões de brasileiros sofrem de osteoporose?"
            :href="`${host}/liberdade#viver-melhor`">
            Leia mais...
          </AButton>
        </div>
      </div>
      <div class="o-news-slider__ref pl-2 pr-2">
        <AColumns class="is-centered is-gapless is-mobile">
          <AColumn class="is-full-mobile is-10-tablet is-8-desktop is-6-widescreen">
            1. (*) Sociedade Brasileira de
            Endocrinologia e Metabologia. 10
            Coisas que Você precisa Saber sobre Osteoporose. <br />Disponível
            em:
            <a href="http://www.endocrino.org.br/10-coisas-que-voce-precisa-saber-sobre-osteoporose/"
              rel="noopener noreferrer"
              target="_blank">http://www.endocrino.org.br/10-coisas-que-voce-precisa-saber-sobre-osteoporose/</a>.
            Acessado em: 29 de maio de 2023.
          </AColumn>
        </AColumns>
      </div>
    </div>
    <div class="o-news-slider__item">
      <!-- <img
        class="o-news-slider__icon"
        src="../../assets/cancer-colorretal.svg"
        alt="Câncer Colorretal"
      /> -->
      <div class="o-news-slider__wrapper p-6">
        <div class="o-news-slider__content">
          <p class="mb-4">
            Câncer colerretal: o que é e quais são os sintomas
          </p>
          <AButton class="p-2 botao-leiamais" component="external"
            title="Leia mais sobre: Câncer colerretal: o que é e quais são os sintomas"
            :href="`${host}/amanha#int-meio-2`">
            Leia mais...
          </AButton>
        </div>
      </div>
    </div>
  </MSlider>
</template>

<script>
import AButton from "@/components/atoms/Button.vue";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import MSlider from "@/components/molecules/Slider.vue";

export default {
  components: {
    AButton,
    AColumn,
    AColumns,
    MSlider
  },
  name: "ONewsSlider",
  setup() {
    const host = `//${document.location.hostname}`;

    return {
      host
    };
  }
};
</script>

<style lang="scss">
$o-news-slider: ".o-news-slider";

#{$o-news-slider} {
  color: $white;
  text-align: center;

  &__controls {
    &--desktop {
      display: none;
    }
  }

  &__item {
    position: relative;
    transition: all 0.2s linear;
  }

  &__ref {
    @extend %position-absolute;
    @extend %translate-x-center;
    bottom: -78px;
    color: #b1b1b1;
    font: normal normal normal 10px/14px Montserrat;
    letter-spacing: -0.15px;
    text-align: left;
    width: calc(100vw - 20px);
  }

  &__icon {
    @extend %position-absolute;
    right: 1rem;
    top: 1rem;
    max-width: 30px;
  }

  &__wrapper {
    @extend %position-absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__content {
    line-height: 0;

    a {
      @extend %default-button;
      border: 1px solid $white;
      background: transparent;
      box-shadow: 0px 6px 22px #00000000;
      display: inline-block;
      font-size: pxToEm(13px);
      line-height: pxToEm(15px);
      max-width: 205px;
      min-width: 150px;
      position: relative;
      width: 90%;

      &::before {
        background: transparent;
      }
    }

    p {
      font-size: pxToEm(16px);
      font-weight: 300;
      line-height: pxToEm(24px);
    }
  }

  .slick-list {
    padding-bottom: 56px !important;
  }

  .slick-slide {
    &:nth-of-type(2) {
      #{$o-news-slider}__item {
        background-color: #FFFFFF;
        color: #70CDED !important;
        box-shadow: 0px 10px 30px #00000029;
      }

      .botao-leiamais {
        color: #70CDED;
        border: 1px solid #70CDED !important;
      }
    }
  }

  #{$o-news-slider}__item {
    background-color: #EFEFEF;
    color: #B4B4B4 !important;
    min-height: 34vh;

    .botao-leiamais {
      color: #B4B4B4;
      border: 1px solid #B4B4B4 !important;
    }
  }

  @include media(">=tablet") {
    #{$o-news-slider} {
      &__ref {
        bottom: -60px;
      }
    }

    .slick-list {
      padding-bottom: 70px !important;
    }
  }

  @include media(">=desktop") {
    p {
      font-size: pxToEm(23px);
      line-height: pxToEm(20px);
    }

    &__icon {
      max-width: 40px;
    }

    .slick-list {
      padding-bottom: 60px !important;
      padding-top: 18px !important;
    }
  }

  @include media(">=widescreen") {
    #{$o-news-slider} {
      &__ref {
        bottom: -70px;
      }
    }

    .slick-list {
      padding-bottom: 90px !important;
    }
  }

  @include media(">=widescreen") {
    #{$o-news-slider} {
      &__ref {
        bottom: -50px;
      }
    }

    .slick-list {
      padding-bottom: 70px !important;
    }
  }
}
</style>
