<template>
  <ASection class="main-section pb-0 pl-0 pr-0" id="main-section">
    <main class="main" id="main">
      <OBenefits />
      <OMedicines />
      <OPatientForm />
      <ONews />
    </main>
  </ASection>
</template>

<script>
import ASection from "@/components/atoms/Section.vue";
import OBenefits from "@/components/organisms/Benefits.vue";
import OMedicines from "@/components/organisms/Medicines.vue";
import ONews from "@/components/organisms/News.vue";
import OPatientForm from "@/components/organisms/PatientForm.vue";

export default {
  components: {
    ASection,
    OBenefits,
    OMedicines,
    ONews,
    OPatientForm
  },
  name: "OMain"
};
</script>
