<!-- <template>
  <Teleport to="body">
    <div v-if="showing" class="appModalWrap" :class="{ show: showing }">
      <div class="reveal-modal-bg" @click.self="close"></div>
      <div id="app-modal" class="reveal-modal" @click.self="close">
        <a
          id="close-app-modal"
          class="close-reveal-modal"
          aria-label="close"
          @click.prevent="close"
        >
          ×
        </a>
        <div v-if="step === 1" class="app-text content mb-0">
          <div class="c-image">
            <figure class="image is-3by1">
              <img
                src="img/programa-bemperto.jpg"
                alt="Logo Programa BemPerto"
              />
            </figure>
          </div>
          <h3
            class="is-size-6-mobile is-size-4-tablet has-text-centered c-title mt-4"
          >
            Novidade no <br class="is-hidden-tablet" />
            Programa BemPerto<sup>®</sup>!
          </h3>
          <p>
            O Programa BemPerto<sup>®</sup> apresenta uma novidade que vai
            facilitar o seu acesso à página logada e realização de cadastro no
            nosso programa. Já está disponível para download na Apple Store e na
            Play Store o nosso aplicativo. Nele, será possível realizar cadastro
            para os programas, além de solicitar seus benefícios e ter acesso a
            área aberta como visitante.
          </p>
          <h4
            class="is-size-6-mobile is-size-5-tablet has-text-centered mt-4 c-title"
          >
            Como baixar?
          </h4>
          <button
            @click.self="toggleStep"
            class="c-button mx-auto is-block is-size-6-tablet"
            title="Instruções"
            type="button"
          >
            Saiba como baixar o Aplicativo
          </button>
          <button @click="toggleStep" class="c-button c-right" type="button">
            <img
              class="c-flip"
              src="../../assets/arrow-icon.svg"
              alt="Seta para direita"
            />
          </button>
        </div>
        <div v-else class="c-scroll">
          <Columns class="is-mobile is-multiline is-gapless mb-0">
            <Column
              class="is-full-mobile is-full-tablet is-half-desktop has-text-centered"
            >
              <div class="c-img mb-1">
                <img src="img/android.png" alt="Play Store" />
              </div>
              <div class="content">
                <p class="mb-2">
                  <strong>Android</strong>: O usuário deve acessar a "Play
                  Store" e, na barra de pesquisar, procurar por "Programa
                  BemPerto".
                </p>
                <p class="is-hidden-mobile is-visible-tablet is-hidden-desktop">
                  &nbsp;
                </p>
              </div>
            </Column>
            <Column
              class="is-full-mobile is-full-tablet is-half-desktop has-text-centered"
            >
              <div class="c-img mb-1">
                <img src="img/ios.png" alt="App Store" />
              </div>
              <div class="content">
                <p class="mb-0">
                  <strong>iOS</strong>: Para baixar o app, o usuário deve
                  acessar a "App Store" e, na barra de pesquisar, procurar por
                  "Programa de Paciente BemPerto".
                </p>
              </div>
            </Column>
          </Columns>
          <button @click="toggleStep" class="c-button c-left" type="button">
            <img
              class="is-block"
              src="../../assets/arrow-icon.svg"
              alt="Seta para esquerda"
            />
          </button>
        </div>
        <small class="has-text-white c-approval">
          SC-BRA-CP-00464. Aprovado em Novembro de 2023
        </small>
      </div>
    </div>
  </Teleport>
</template> -->

<script>
import Columns from "@/components/atoms/Columns.vue";
import Column from "@/components/atoms/Column.vue";
import $ from "jquery";

const sessionId = "appModal";

export default {
  components: {
    Column,
    Columns
  },
  computed: {
    isPrerendering: () => window.__PRERENDER_INJECTED
  },
  data() {
    return {
      inSession: null,
      step: 1,
      showing: false
    };
  },
  created() {
    if (!this.isPrerendering) {
      this.inSession = !!sessionStorage.getItem(sessionId);
    }
  },
  mounted() {
    if (!this.isPrerendering) {
      this.openModal();
    }
  },
  methods: {
    openModal() {
      if (this.inSession === false) {
        this.showing = !this.isPrerendering;
      }
    },

    toggleStep() {
      this.step = [1, 2][+(this.step === 1)];
    },

    close() {
      sessionStorage.setItem(sessionId, sessionId);

      this.showing = false;
    }
  },
  watch: {
    showing(value) {
      const isClipped = "is-clipped";

      if (value) {
        return $("html, body").addClass(isClipped);
      }

      $("html, body").removeClass(isClipped);
    }
  }
};
</script>

<style lang="scss">
.appModalWrap {
  display: none;

  &.show {
    display: block;
  }

  .content {
    p {
      color: #666;
      font-size: 14px;
      line-height: 20px;

      @include media(">=tablet") {
        font-size: 1rem;
        line-height: 22px;
      }
    }

    .c-flip {
      transform: rotateY(180deg);
    }

    .c-image {
      margin: 0 auto;
      max-width: 220px;
    }

    .c-title {
      color: #0a629b;
    }
  }

  .c-img {
    @include media(">=desktop") {
      height: 480px;
      position: relative;

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
  }

  .c-approval {
    bottom: -24px;
    font-size: 11px;
    left: 0;
    position: absolute;
  }

  .c-button {
    appearance: none;
    background: none;
    border: none;
    color: #666;
    font-family: inherit;
    font-weight: normal;
    padding: 0;
    text-rendering: optimizeLegibility;
  }

  .c-right {
    border-radius: 50%;
    bottom: 32px;
    right: 10px;
    position: absolute;
  }

  .c-left {
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.89) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 50%;
    bottom: 32px;
    left: 10px;
    position: absolute;
  }

  .c-scroll {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;

    p {
      @include media(">=desktop") {
        padding: 0.5rem 1rem 0;
      }
    }
  }

  .reveal-modal-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 99, 195, 0.7);
    z-index: 1004;
    opacity: 1;
  }

  .reveal-modal,
  dialog {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 94%;
    margin: 0 auto;
    padding: 1rem 1.875rem 1.875rem;
    border: solid 1px #666666;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    opacity: 1;
    transform: translateY(-50%);
    z-index: 1005;

    @include media(">=desktop") {
      min-height: 380px;
    }

    @include media(">=widescreen") {
      max-width: 1024px;
    }

    p {
      font-family: inherit;
      font-weight: normal;
      margin-bottom: 1.25rem;
      text-rendering: optimizeLegibility;
    }
  }

  .reveal-modal .close-reveal-modal,
  dialog .close-reveal-modal {
    font-size: 2.5rem;
    line-height: 1;
    position: absolute;
    top: 0.5rem;
    right: 0.6875rem;
    color: #aaaaaa;
    font-weight: bold;
    cursor: pointer;
  }

  .reveal-modal > :first-child,
  dialog > :first-child {
    margin-top: 0;
  }
}
</style>
