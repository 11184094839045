<template>
  <footer class="footer pt-0" id="footer">
    <BackToTop />
    <AContainer
      v-if="showContactBlock"
      class="b-footer-contact is-fluid pb-6 pl-0 pr-0 pt-1"
    >
      <MContact class="has-text-centered" />
    </AContainer>
    <AContainer class="b-footer-menu is-fluid pb-5 pl-0 pr-0 pt-5">
      <nav>
        <AColumns
          class="is-centered is-gapless is-mobile is-multiline is-vcentered"
          tag="ul"
        >
          <AColumn class="has-text-centered is-full mb-1" tag="li">
            <AColumns
              class="is-centered is-gapless is-mobile is-multiline is-vcentered"
            >
              <AColumn class="has-text-centered is-3-mobile">
                <a
                  href="https://www.amgen.com.br"
                  title="Amgen Biotecnologia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ALogoAmgen style="width: 100px;"/>
                </a>
              </AColumn>
            </AColumns>
          </AColumn>
          <AColumn class="has-text-centered is-5-tablet" tag="li">
            <a
              href="https://www.amgen.com.br/privacy-statement/"
              rel="noopener noreferrer"
              target="_blank"
              title="Toque aqui para ler nossa política de privacidade"
            >
              Política de privacidade
            </a>
            <a
              href="https://www.amgen.com.br/terms-of-use/"
              rel="noopener noreferrer"
              target="_blank"
              title="Toque aqui para ler nossos termos de uso"
            >
              Termos de uso
            </a>
          </AColumn>
        </AColumns>
      </nav>
    </AContainer>
    <AContainer class="b-footer-rights is-fluid pl-3 pr-3 pt-3">
      <AContent class="has-text-centered">
        <p>
          © 2018 - {{ new Date().getFullYear() }} Amgen Inc. Todos os direitos
          reservados.
        </p>
        <p>
          SC-BRA-NP-605. Aprovado em Fevereiro/2024.<br />
          As informações contidas neste site são apenas informativas e não
          substituem qualquer orientação fornecida pelo seu médico. Todo e
          qualquer diagnóstico deve ser realizado por um médico.
        </p>
      </AContent>
    </AContainer>
  </footer>
</template>

<script>
import $ from "jquery";
import { onMounted } from "vue";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import AContainer from "@/components/atoms/Container.vue";
import ALogoAmgen from "@/components/atoms/LogoAmgen.vue";
import AContent from "@/components/atoms/Content.vue";
import MContact from "@/components/molecules/Contact.vue";
import BackToTop from "@/components/atoms/BackToTop.vue";

export default {
  components: {
    AColumn,
    AColumns,
    AContainer,
    AContent,
    ALogoAmgen,
    MContact,
    BackToTop
  },
  name: "OFooter",
  props: {
    showContactBlock: {
      default: true,
      required: false,
      type: Boolean
    }
  },
  setup() {
    const host = `//${document.location.hostname}`;

    onMounted(() => {
      if (document.location.hash === "#footer") {
        goToElement("#footer");
      }
    });

    function goToElement(id) {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(id).offset().top - 40
        },
        1000
      );
    }

    return {
      host
    };
  }
};
</script>

<style lang="scss">
@import "bulma/sass/layout/footer.sass";

#footer {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  a {
    color: $dove-gray;
    font-size: pxToEm(10px);
    font-weight: 600;
    line-height: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .b-footer- {
    &contact {
      .m-section-title {
        margin-bottom: 0;
      }

      .m-contact__ {
        &phone {
          font-weight: 600;
          letter-spacing: 9.6px;
        }
      }
    }

    &menu {
      background-color: $gallery;

      li {
        &:nth-of-type(2) {
          a:nth-of-type(2) {
            &::before {
              content: "|";
              display: inline-block;
            }
          }
        }
      }
    }

    &rights {
      background-color: $mercury;
      font-size: pxToEm(11px);
      padding-bottom: 80px;

      p:nth-last-of-type(1) {
        font-size: pxToEm(10px);
      }
    }
  }

  @include media(">=tablet") {
    a {
      font-size: pxToEm(12px);
    }

    .b-footer- {
      &contact {
        .m-contact__ {
          &phone {
            color: $lochmara;
            font-weight: 400 !important;

            span {
              border: 1px solid #2f91d085;
              border-radius: 6px;
              display: inline-block;
              letter-spacing: 6.6px;
              padding: 24px 60px;
            }
          }
        }
      }

      &rights {
        font-size: pxToEm(12px);
        padding-bottom: 2vh;

        p:nth-last-of-type(1) {
          font-size: pxToEm(11px);
        }
      }
    }
  }
}
</style>
