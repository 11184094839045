<template>
  <AContainer class="b-patient-form is-fluid">
    <div class="o-patient-form has-text-centered" id="patient-form" style="margin-bottom: 70px;">
      <MPatientFormTabs :defaultTab="activeForm" :onChangeTab="onChangeTab" />
      <form @submit.prevent="submit" method="post">
        <div v-if="activeForm === 'faca-parte'" class="o-patient-form__signup" id="faca-parte">
          <ATextInput id="cpf" name="cpf" label="CPF" mask="###.###.###-##" :error="error.cpf"
            :onBlur="$event => validateField($event, 'cpf')" required v-model="state.cpf" />
          <APasswordInput id="password" name="password" label="Senha" :error="error.password ? true : false"
            :onInput="$event => validateField($event, 'password')" required v-model="state.password" />
          <div v-if="state.password" class="password-hints has-text-left">
            <small :class="{
              'is-invalid':
                vl.password.minLength.$invalid ||
                vl.password.maxLength.$invalid
            }">A senha deve ter 8 caracteres.</small>
            <small :class="{ 'is-invalid': vl.password.mustBeAlphaNum.$invalid }">A senha deve conter letras e
              números.</small>
          </div>
          <APasswordInput id="password-confirm" name="password-confirm" label="Confirme a senha"
            :error="error.passwordConfirm ? true : false" :onInput="$event => validateField($event, 'passwordConfirm')"
            required v-model="state.passwordConfirm" />
          <div v-if="state.passwordConfirm" class="password-hints has-text-left">
            <small :class="{
              'is-invalid': vl.passwordConfirm.sameAsPassword.$invalid
            }">Senha e confirmação de senha devem ser iguais.</small>
          </div>
          <ASelectInput :class="state.pathology.value" id="pathology" name="pathology" label="Patologia"
            :error="error['pathology.value']" :options="pathologies" :onChange="onChangePathology"
            v-model="state.pathology" required />
          <AButton class="mt-3 p-4 botao-entrar" type="submit" style="letter-spacing: 2.6px; font-weight: 600;"
            v-on:click="customRequiredMSG()">ENTRAR</AButton>
        </div>
        <div v-else class="o-patient-form__login" id="sou-participante">
          <ATextInput id="cpf" name="cpf" label="CPF" mask="###.###.###-##" :error="error.cpf"
            :onBlur="$event => validateField($event, 'cpf')" required v-model="state.cpf" />
          <APasswordInput id="password" name="password" label="Senha" :error="error.password"
            :onInput="$event => validateField($event, 'password')" required v-model="state.password" />
          <ASelectInput :class="state.pathology.value" id="pathology" :error="error['pathology.value']" name="pathology"
            label="Patologia" :options="pathologies" :onChange="onChangePathology" v-model="state.pathology" />
          <ASelectInput class="select-medicine" v-show="getMedicinesByPathology().length" id="medicine"
            :error="error['medicine.value']" name="medicine" label="Medicamento" :options="getMedicinesByPathology()"
            :onChange="onChangeMedicine" v-model="state.medicine" />
          <a :href="forgotPasswordLink" title="Clique aqui para redefinir sua senha">
            <p class="esqueci" style="color: #70CDED;">Esqueci minha senha</p>
          </a>
          <AButton class="mt-3 p-4 botao-entrar" type="submit" style="letter-spacing: 2.6px; font-weight: 600;"
            v-on:click="customRequiredMSG()">ENTRAR</AButton>
        </div>
      </form>
    </div>
  </AContainer>
</template>

<script>
import $ from "jquery";
import useVuelidate from "@vuelidate/core";
import {
  alphaNum,
  maxLength,
  minLength,
  required,
  requiredIf
} from "@vuelidate/validators";
import { onMounted, reactive, ref } from "vue";
import AButton from "@/components/atoms/Button.vue";
import AContainer from "@/components/atoms/Container.vue";
import APasswordInput from "@/components/atoms/PasswordInput.vue";
import ASelectInput from "@/components/atoms/SelectInput.vue";
import ATextInput from "@/components/atoms/TextInput.vue";
import MPatientFormTabs from "@/components/molecules/PatientFormTabs.vue";

export default {
  name: "OPatientForm",
  components: {
    AButton,
    AContainer,
    APasswordInput,
    ASelectInput,
    ATextInput,
    MPatientFormTabs
  },
  methods: {
    customRequiredMSG() {
      const cpf = document.querySelector("#cpf");
      const password = document.querySelector("#password");
      const password_confirm = document.querySelector("#password-confirm");
      const pathology = document.querySelector("#pathology");

      if (cpf.validity.valueMissing) {
        cpf.setCustomValidity("Por favor, preencha corretamente CPF");
      } else {
        cpf.setCustomValidity("");
      }
      if (password.validity.valueMissing) {
        password.setCustomValidity("Por favor, preencha com uma Senha");
      } else {
        password.setCustomValidity("");
      }
      if (password_confirm) {
        if (password_confirm.validity.valueMissing) {
          password_confirm.setCustomValidity(
            "Por favor, preencha a Confirmação da Senha"
          );
        } else {
          password_confirm.setCustomValidity("");
        }
      }
      if (pathology.validity.valueMissing) {
        pathology.setCustomValidity("Por favor, escolha uma Patologia");
      } else {
        pathology.setCustomValidity("");
      }
    }
  },
  setup() {
    const host = `//${document.location.hostname}`;
    const activeForm = ref("faca-parte");

    const error = ref({
      cpf: "",
      password: "",
      passwordConfirm: "",
      "pathology.value": "",
      "medicine.value": ""
    });

    const state = reactive({
      cpf: "",
      password: ref(""),
      passwordLogin: "",
      passwordConfirm: "",
      pathology: {
        label: "",
        value: null
      },
      medicine: {
        label: "",
        value: null
      }
    });

    const pathologies = [
      {
        label: "Osteoporose",
        value: "osteoporose"
      },
      {
        label: "Câncer Colorretal",
        value: "cancer-colorretal"
      },
      {
        label:
          'Leucemia Linfoblástica Aguda - (<span class="is-uppercase">lla</span>)',
        value: "leucemia-linfoblastica-aguda"
      },
      {
        label: "Doença Autoimune",
        value: "doenca-autoimune"
      }
    ];

    const medicines = [
      {
        pathology: "osteoporose",
        medicines: [
          { label: "Evenity®", value: "evenity" },
          { label: "Prolia®", value: "prolia" }
        ]
      },
      {
        pathology: "doenca-autoimune",
        medicines: [{ label: "Amgevita®", value: "amgevita" }]
      }
    ];

    const isValidCpf = value => {
      let sum = 0;
      let mod = 0;
      const cpfNumber = value.replace(/\D+/g, "");

      if (
        cpfNumber === "00000000000" ||
        cpfNumber === "11111111111" ||
        cpfNumber === "22222222222" ||
        cpfNumber === "33333333333" ||
        cpfNumber === "44444444444" ||
        cpfNumber === "55555555555" ||
        cpfNumber === "66666666666" ||
        cpfNumber === "77777777777" ||
        cpfNumber === "88888888888" ||
        cpfNumber === "99999999999"
      )
        return false;

      for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpfNumber.substring(i - 1, i)) * (11 - i);
      }

      mod = (sum * 10) % 11;

      if (mod === 10 || mod === 11) mod = 0;
      if (mod !== parseInt(cpfNumber.substring(9, 10))) return false;

      sum = 0;
      for (let i = 1; i <= 10; i++)
        sum = sum + parseInt(cpfNumber.substring(i - 1, i)) * (12 - i);
      mod = (sum * 10) % 11;

      if (mod === 10 || mod === 11) mod = 0;
      if (mod !== parseInt(cpfNumber.substring(10, 11))) return false;

      return true;
    };

    const mustBeAlphaNum = value => {
      if (value.match(/^(?=.*\d)(?=.*[a-zA-Z]).{1,100}$/)) {
        return true;
      }

      return false;
    };

    const sameAsPassword = value => state.password === value;

    const rules = {
      cpf: { isValidCpf, required },
      password: {
        minLength: minLength(8),
        maxLength: maxLength(8),
        alphaNum,
        mustBeAlphaNum,
        requiredIf: requiredIf(activeForm.value === "faca-parte")
      },
      passwordLogin: {
        requiredIf: requiredIf(activeForm.value === "sou-participante")
      },
      passwordConfirm: {
        sameAsPassword,
        requiredIf: requiredIf(activeForm.value === "faca-parte")
      },
      pathology: {
        value: {
          required
        }
      }
    };

    const rules2 = {
      cpf: rules.cpf,
      password: {
        required
      },
      pathology: rules.pathology
    };

    const vl = useVuelidate(rules, state);
    const vl2 = useVuelidate(rules2, state);

    const submit = () => {
      if (validateForm(activeForm.value === "sou-participante" ? vl2 : vl)) {
        let redirectUrl = getProgramUrl("cadastro");
        let storageKey = "facaParte";
        const userData = {
          cpf: state.cpf,
          password: btoa(state.password),
          pathology: state.pathology.value,
          medicine: getMedicineName()
        };

        if (activeForm.value === "sou-participante") {
          storageKey = "souParticipante";
          redirectUrl = getProgramUrl("login-cadastro");
        }

        localStorage.setItem(storageKey, JSON.stringify(userData));

        return (window.location = redirectUrl);
      }
    };

    function getMedicinesByPathology() {
      const [medicinesList] = medicines.filter(
        m => m.pathology === state.pathology.value
      );

      return medicinesList ? medicinesList.medicines : [];
    }

    function getMedicineName() {
      switch (state.pathology.value) {
        case "doenca-autoimune":
        case "osteoporose":
          return state.medicine.value;

        case "cancer-colorretal":
          return "vectibix";

        default:
          return "blincyto";
      }
    }

    function getProgramUrl(path) {
      let url = host;

      switch (state.pathology.value) {
        case "osteoporose":
          url += "/liberdade";
          break;

        case "cancer-colorretal":
          url += "/amanha";
          break;

        case "doenca-autoimune":
          url += "/bemestar";
          break;

        default:
          url += "/dequemvoceama";
          break;
      }

      return `${url}/${path}`;
    }

    function validateField(e, field, path = null) {
      let status = true;

      if (!e.target.value) return status;

      let formV = activeForm.value === "sou-participante" ? vl2 : vl;

      if (formV.value[field]) {
        formV.value[field].$touch();

        if (formV.value[field].$error) {
          setErrorMessages(formV.value[field].$errors);

          status = false;
        }
      }

      if (status) {
        const prop = path ? path : field;

        error.value[prop] = "";
      }

      return status;
    }

    function validateForm(formV) {
      let status = true;

      formV.value.$touch();

      if (formV.value.$error) {
        setErrorMessages(formV.value.$errors);

        status = false;
      }

      if (
        activeForm.value === "sou-participante" &&
        state.pathology.value === "osteoporose" &&
        !state.medicine.value
      ) {
        setErrorMessages([
          { $propertyPath: "medicine.value", $validator: "required" }
        ]);

        status = false;
      }

      return status;
    }

    function setErrorMessages(errors) {
      errors.forEach(err => {
        error.value[err.$propertyPath] = getErrorMessage(err.$validator);
      });
    }

    function getErrorMessage(validator) {
      let errorMessage;

      switch (validator) {
        case "required":
          errorMessage = "Campo obrigatório.";
          break;

        case "requiredIf":
          errorMessage = "Campo obrigatório.";
          break;

        case "sameAs":
          errorMessage = "Senha e confirmação de senha não conferem.";
          break;

        case "isValidCpf":
          errorMessage = "CPF inválido.";
          break;

        case "maxLength":
        case "minLength":
          errorMessage = "A senha deve ter 8 caracteres.";
          break;

        case "alphaNum":
          errorMessage = "Caracteres especiais não permitidos.";
          break;

        case "mustBeAlphaNum":
          errorMessage = "A senha deve conter letras e números.";
          break;

        default:
          errorMessage = "";
          break;
      }

      return errorMessage;
    }

    function onChangeTab(activeTab) {
      activeForm.value = activeTab;
    }

    function onChangePathology(selectedPathology) {
      state.pathology = selectedPathology;

      validateField(
        { target: { value: selectedPathology } },
        "pathology",
        "pathology.value"
      );
    }

    function onChangeMedicine(selectedMedicine) {
      state.medicine = selectedMedicine;

      validateField(
        { target: { value: selectedMedicine } },
        "medicine",
        "medicine.value"
      );
    }

    function goToElement(id) {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $(id).offset().top - 80
        },
        1000
      );
    }

    window.onhashchange = () => {
      if (
        document.location.hash.match("faca-parte") ||
        document.location.hash.match("sou-participante")
      ) {
        activeForm.value = document.location.hash.replace("#", "");
      }
    };

    onMounted(() => {
      if (
        document.location.hash.match("faca-parte") ||
        document.location.hash.match("sou-participante")
      ) {
        goToElement("#faca-parte");
        activeForm.value = document.location.hash.replace("#", "");
      }
    });

    return {
      activeForm,
      error,
      getMedicinesByPathology,
      medicines,
      onChangeMedicine,
      onChangePathology,
      onChangeTab,
      pathologies,
      state,
      submit,
      validateField,
      vl,
      vl2
    };
  },
  data: function () {
    return {
      forgotPasswordLink: ""
    };
  },
  created() {
    this.forgotPasswordLink = "//" + window.location.host + "/esqueci-a-senha";
  }
};
</script>

<style lang="scss">
.o-patient-form {

  &__signup,
  &__login {
    background-color: $white;
    margin: 1.5rem auto 0;
    max-width: 80%;
  }

  .password-hints {
    margin-bottom: 0.6rem;

    small {
      color: green;
      display: block;
      font-size: pxToEm(11px);
      margin-bottom: 0.4rem;

      &::before {
        @extend %pseudo-el;
        background-image: url("../../assets/check-pass.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        display: inline-block;
        height: 12px;
        margin-right: 4px;
        margin-top: -3px;
        vertical-align: middle;
        width: 12px;
      }

      &.is-invalid {
        color: red;

        &::before {
          background-image: url("../../assets/red-close.svg");
          height: 10px;
          width: 10px;
        }
      }
    }
  }

  .botao-entrar {
    background-color: #70CDED !important;
    border-bottom: 0 !important;

    &:hover {
      background-color: #46c4ee !important;
      color: $white !important;
    }

    &::before {
      background: #70CDED !important;
    }
  }

  .a-select-input {
    input {
      font-weight: 600;
      text-transform: lowercase;
    }

    label::before {
      background-color: #70CDED !important;
      width: 42px;
      margin-right: -3px;
      height: 43px;
    }

    &.select-medicine {

      .input,
      .a-select-input__options {
        text-transform: capitalize !important;
      }
    }

    &.osteoporose,
    &.cancer-colorretal,
    &.leucemia-linfoblastica-aguda,
    &.doenca-autoimune {
      label {
        &::before {
          background-color: $white;
          opacity: 0.2;
        }
      }

      .input {
        color: $white;
      }
    }

    &.osteoporose {
      .input {
        background-color: #906098;
      }
    }

    &.cancer-colorretal {
      .input {
        background-color: #70bb71;
      }
    }

    &.leucemia-linfoblastica-aguda {
      .input {
        background-color: #fa968a;
      }
    }

    &.doenca-autoimune {
      .input {
        background-color: #ec5930;
      }
    }

    &__options {
      .osteoporose {
        color: #906098;
      }

      .cancer-colorretal {
        color: #70bb71;
      }

      .leucemia-linfoblastica-aguda {
        color: #fa968a;
      }

      .doenca-autoimune {
        color: #ec5930;
      }
    }
  }

  .a-button {
    @extend %default-button;
  }
}

s .b-patient-form {
  margin-bottom: 6rem;
}

@include media(">=tablet") {
  .b-patient-form {
    z-index: 5;
  }

  .o-patient-form {

    &__signup,
    &__login {
      box-shadow: 0px 6px 16px #00000029;
      border-radius: 8px;
      margin-top: -28px;
      max-width: 688px;
      padding: 3.4rem 2.4rem;
    }

    .a-button {
      bottom: -24px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
}

@include media(">=desktop") {
  .b-patient-form {
    margin-top: 0px;
  }
}

@include media(">=widescreen") {
  .b-patient-form {
    margin-bottom: 0;
  }
}

.o-patient-form .a-select-input.osteoporose .input {
  background-color: #278DAA !important;
}

.osteoporose {
  color: #278DAA !important;
}
</style>
