<template>
  <router-view></router-view>
</template>

<script>
import { watch } from "vue";
import { useRoute } from "vue-router";
import $ from "jquery";

export default {
  name: "App",
  setup() {
    const route = useRoute();
    let pageBodyClassPrefix = "page-";

    watch(
      () => route.path,
      async path => {
        const page = path.replace("/", "");
        let pageBodyClass = pageBodyClassPrefix;

        if (!page) {
          pageBodyClass += "home";
        } else {
          pageBodyClass += page;
        }

        $("body")
          .removeClass((index, css) =>
            (css.match(/(^|\s)page-\S+/g) || []).join(" ")
          )
          .addClass(pageBodyClass);
      }
    );
  }
};
</script>
