<template>
  <AContainer class="b-benefits is-fluid pl-0 pr-0 pb-5">
    <div class="b-benefits__content">
      <ASectionTitle>
        <template v-slot:icon>
          <img
            class="is-hidden-tablet"
            src="../../assets/like-blue.svg"
            alt="Ícone benefícios"
          />
          <img
            class="is-hidden-mobile"
            src="../../assets/like-blue.svg"
            alt="Ícone benefícios BemPerto"
          />
        </template>
        <template v-slot:title ><span style="color: #70CDED !important;"> Sobre o programa </span></template>
      </ASectionTitle>
      <div class="o-benefits has-text-centered">
        <span id="spanOque" style="color: white; font-weight: 300;">O que o</span>
        <br/><img
          id="bempertoimg"
          src="../../assets/Bemperto_azul_branco.svg"
          style="margin-top: -15px;"
          alt="O que o BemPerto traz para você"
        /><br/>
        <span style="color: white; font-weight: 300;">traz para você?</span>
        <AButton
          class="o-benefits__action p-3"
          component="link"
          to="/sobre-o-programa"
        >
          Saiba Mais
        </AButton>
      </div>
    </div>
  </AContainer>
</template>

<script>
import AButton from "@/components/atoms/Button.vue";
import AContainer from "@/components/atoms/Container.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";

export default {
  components: {
    AButton,
    AContainer,
    ASectionTitle
  },
  name: "OBenefits"
};
</script>

<style lang="scss">
.o-benefits {
  background-color: #70CDED;
  // border: 1px solid $lochmara;
  border-radius: 10px;
  // margin-top: 1rem;
  // padding: 2.4rem 1.8rem;
  position: relative;

  &__action {

    @extend %position-absolute;
    @extend %translate-x-center;
    // border-bottom: 2px solid #1880c370;
    background-color: white !important;
    // bottom: -20px;
    font-size: pxToEm(12px);
    max-width: 202px;
    min-width: 172px;
    width: 70%;
    color: #70CDED !important;
    font-weight: 700;
  }
}

@include media(">=tablet") {
  .b-benefits {
    // background-image: url("../../assets/blue-wave.svg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    min-height: 20vh;
    // padding-top: calc(42% + 10px);

    &__content {
      left: 50%;
      max-width: 1005px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: calc(50% - 16px);
      width: 86%;
    }

    .m-section-title__title {
      color: #70CDED;
      font-weight: 600;
    }
  }

  .o-benefits {
    border-color: #a0daff;
    box-shadow: 0px 6px 8px #00000015;
    margin: 0.4rem auto 0;
    padding: 2rem 1.8rem;

    img {
      max-width: 26%;
    }

    &__action {
      bottom: -16px;
      max-width: 182px;
      padding: 0.6rem;
      text-transform: initial;
    }
  }
}

@include media(">=desktop") {
  .b-benefits {
    // margin-top: 30px;

    &__content {
      // top: calc(50% + 18px);
    }
  }

  .o-benefits {
    margin: 1rem auto 0;
    padding: 2.8rem;

    &__action {
      bottom: -24px;
      padding: 1rem;
    }
  }
}

@include media(">=widescreen") {
  .b-benefits {
    &__content {
      margin-top: 0;
    }
  }

  .o-benefits {
    margin: 1.2rem auto 0;
    padding: 3.8rem;

    img {
      max-width: 32%;
    }

    &__action {
      font-size: pxToEm(14px);
    }
  }
}

@include media(">=fullhd") {
  .o-benefits {
    padding: 4.8rem;

    img {
      max-width: 34%;
    }

    &__action {
      font-size: pxToEm(14px);
    }
  }
}

#spanOque{
    font-size: 29px; 
  }

@media (min-width: 400px){
  #bempertoimg{
    width: 200px;
  }

  #spanOque{
    font-size: 20px; 
  }

  .o-benefits{
    padding: 40px;
  }

  .o-benefits__action{
    position: absolute !important;
    bottom: -15px;
  }
}



</style>
