const InputMixin = {
  props: {
    error: {
      default: false,
      required: false
    },
    id: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    onBlur: {
      default: () => {},
      required: false,
      type: Function
    },
    onInput: {
      default: () => {},
      required: false,
      type: Function
    },
    modelValue: {
      required: true
    },
    required: {
      default: false,
      required: false,
      type: Boolean
    }
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  }
};

export default InputMixin;
