<template>
  <AContainer
    class="b-medicines is-fluid pl-0 pr-0 pb-5"
    style="margin-bottom: 10px; margin-top: 150px;"
    id="participating-programs"
  >
    <ASectionTitle>
      <template v-slot:icon>
        <img
          src="../../assets/icones/checkmark.svg"
          alt="Ícone programas participantes"
        />
      </template>
      <template v-slot:title>
        <span style="color: #70CDED; font-weight: 600;">
         ÁREAS DE ATUAÇÂO
        </span> 
      </template>
    </ASectionTitle>
    <OMedicinesSlider />
  </AContainer>
</template>

<script>
import AContainer from "@/components/atoms/Container.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";
import OMedicinesSlider from "@/components/organisms/MedicinesSlider.vue";

export default {
  components: {
    AContainer,
    ASectionTitle,
    OMedicinesSlider
  },
  name: "OMedicines"
};
</script>

<style lang="scss">
.b-medicines {
  margin: 3rem auto 4rem;

  @include media(">=tablet") {
    margin-top: 6rem;
  }

  @include media(">=desktop") {
    margin-bottom: -120px;
    margin-top: 6rem;
    z-index: 2;
  }

  @include media(">=widescreen") {
    margin-bottom: -150px;
  }

  @include media(">=1600px") {
    margin-bottom: -200px;
  }
}

.b-medicines {
  color: #70CDED !important;
}
</style>
