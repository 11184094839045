<template>
  <Teleport to="body">
    <div v-if="showing" class="lgdpModalWrap" :class="{ show: showing }">
      <div class="reveal-modal-bg" @click.self="close"></div>
      <div id="cookie-consent-modal" class="reveal-modal" @click.self="close">
        <a
          id="close-cookie-consent-modal"
          class="close-reveal-modal"
          aria-label="close"
          @click.prevent="close"
        >
          ×
        </a>
        <div class="cookie-text">
          <p>
            Este site utiliza cookies para oferecer a você a melhor e mais
            relevante experiência. Ao utilizar este site e continuar a navegar,
            você concorda com a nossa política de utilização de cookies. Para
            obter mais informações sobre cookies, visite nossa <br />
            <a
              class="amgen-link-modal-link"
              href="https://www.amgen.com.br/privacy-statement"
              target="_blank"
            >
              Política de Privacidade.
            </a>
          </p>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import $ from "jquery";

export default {
  computed: {
    isPrerendering: () => window.__PRERENDER_INJECTED
  },
  data() {
    return {
      cookie: null,
      showing: false
    };
  },
  created() {
    if (!this.isPrerendering) {
      this.cookie = this.$cookies.isKey("amgenLGPD");
    }
  },
  mounted() {
    if (!this.isPrerendering) {
      this.openModal();
    }
  },
  methods: {
    openModal() {
      if (this.cookie === false) {
        this.showing = !this.isPrerendering;
      }
    },
    close() {
      this.$cookies.set("amgenLGPD");
      this.showing = false;
    }
  },
  watch: {
    showing(value) {
      const isClipped = "is-clipped";

      if (value) {
        $("html, body").addClass(isClipped);
      }
    }
  }
};
</script>

<style lang="scss">
.lgdpModalWrap {
  display: none;
}
.lgdpModalWrap.show {
  display: block;
}
.reveal-modal-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 99, 195, 0.7);
  z-index: 1004;
  opacity: 1;
}
.reveal-modal,
dialog {
  position: absolute;
  top: 10vh;
  left: 0;
  right: 0;
  width: 80%;
  margin: 0 auto;
  padding: 1.875rem;
  border: solid 1px #666666;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
  opacity: 1;
  z-index: 1005;
  p {
    color: #252525;
    font-family: inherit;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 1.25rem;
    text-rendering: optimizeLegibility;
  }
}
.reveal-modal .close-reveal-modal,
dialog .close-reveal-modal {
  font-size: 2.5rem;
  line-height: 1;
  position: absolute;
  top: 0.5rem;
  right: 0.6875rem;
  color: #aaaaaa;
  font-weight: bold;
  cursor: pointer;
}
.reveal-modal > :first-child,
dialog > :first-child {
  margin-top: 0;
}
</style>
