<template>
  <ASection class="header-section is-paddingless" id="header-section">
    <transition name="fade">
      <AppModal />
    </transition>
    <transition name="fade">
      <LgpdModal />
    </transition>
    <header class="pb-4 espaco-img" id="">
      <div id="header">
        <AColumns class="is-gapless is-mobile">
          <AColumn
            class="logo-column has-text-centered has-text-right-tablet is-half-mobile is-one-quarter-tablet is-4-desktop">
            <router-link to="/" title="Programa BemPerto">
              <ALogo class="a-logo is-hidden-mobile mt-4 p-5" :logoType="topLogo" />
            </router-link>
          </AColumn>
          <AColumn>
            <MNav />
          </AColumn>
        </AColumns>
      </div>
      <!-- <div v-if="isSobrePrograma()"> -->
      <div>
        <div v-if="showSlider" class="area-slider" ref="areaSlider">
          <ul class="area-slider__slides">
            <div v-if="isMobile()">
              <li class="area-slider__slide">
                <img src="../../assets/BannerTopoDesktopNovo.png" alt="Programa Bemperto de quem você ama"
                  class="area-slider__slide__img" />
              </li>
            </div>
            <div v-else>
              <li class="area-slider__slide">
                <img src="../../assets/BannerTopoDesktopNovo.png" alt="Programa Bemperto de quem você ama"
                  class="area-slider__slide__img" />
              </li>

            </div>
          </ul>
        </div>
      </div>
      <!-- <div v-if="showBottomLogo" class="logo-bottom-wrapper">
        <AColumns class="is-gapless">
          <AColumn class="a-logo-bottom has-text-centered is-full">
            <router-link to="/" title="Programa BemPerto">
              <ALogo :logoType="bottomLogo" />
            </router-link>
          </AColumn>
        </AColumns>
      </div> -->
    </header>
  </ASection>
</template>

<script>
import $ from "jquery";
import { onMounted, ref } from "vue";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import ALogo from "@/components/atoms/Logo.vue";
import ASection from "@/components/atoms/Section.vue";
import MNav from "@/components/molecules/Nav.vue";
import LgpdModal from "@/components/atoms/LgpdModal.vue";
import AppModal from "@/components/molecules/AppModal.vue";

export default {
  components: {
    AColumn,
    AColumns,
    ALogo,
    ASection,
    MNav,
    LgpdModal,
    AppModal
  },
  props: {
    bottomLogo: {
      required: false,
      type: String
    },
    showBottomLogo: {
      default: true,
      required: false,
      type: Boolean
    },
    showSlider: {
      default: true,
      required: false,
      type: Boolean
    },
    topLogo: {
      default: "simple",
      required: false,
      type: String
    }
  },
  name: "OHeader",
  methods: {

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isSobrePrograma() {

      if (window.location.pathname == "/sobre-o-programa") {
        return false
      } else {
        return true;
      }
    }
  },
  setup() {
    const areaSlider = ref(null);
    onMounted(() => {
      const Slider = {
        init: function () {
          this.slideDuration = 7000;
          this.slideSelector = ".area-slider__slide";
          this.slideInClass = "activeFadeInZoom";
          this.$slider = $(areaSlider.value);
          this.$firstSlide = this.$slider.find(this.slideSelector + ":first");
          this.$currentSlide = this.$firstSlide;

          this.manageSlides(null);
        },

        manageSlides: function ($prevSlide) {
          var self = this;

          this.nextSlide($prevSlide);

          setTimeout(function () {
            self.transitionSlide(self.$currentSlide, function ($slide) {
              self.manageSlides($slide);
            });
          }, this.slideDuration);
        },

        transitionSlide: function ($slide, callback) {
          this.setNextSlide();

          var previousSlider = $slide.prev(this.slideSelector);

          if (!previousSlider.length) {
            previousSlider = this.$slider.find(this.slideSelector + ":last");
          }

          callback(previousSlider);
        },

        nextSlide: function ($prevSlide) {
          this.$currentSlide.addClass(this.slideInClass);

          if ($prevSlide && $prevSlide.length) {
            $prevSlide.removeClass(this.slideInClass);
          }
        },

        setNextSlide: function () {
          this.$currentSlide = this.$currentSlide.next(this.slideSelector);

          if (!this.$currentSlide.length) {
            this.$currentSlide = this.$firstSlide;
          }
        }
      };

      Slider.init();
    });

    return {
      areaSlider
    };
  }
};



</script>

<style lang="scss">
.header-section {
  // &::before {
  //   @extend %position-absolute;
  //   @extend %pseudo-el;
  //   background-image: url("../../assets/smart-waves-top.png");
  //   background-position: center top;
  //   background-repeat: no-repeat;
  //   background-size: 100%;
  //   display: none;
  //   height: 100%;
  //   left: 0;
  //   pointer-events: none;
  //   width: 100%;
  //   z-index: 2;
  // }

  @include media(">=tablet") {
    &::before {
      display: block;
    }
  }
}


#header {
  justify-content: center;
  // overflow: hidden;
  // padding-top: 10vh;
  position: relative;

  &::before,
  &::after {
    @extend %position-absolute;
    @extend %pseudo-el;
    background-repeat: no-repeat;
    background-size: 100%;
    display: none;
    height: 100%;
    left: 0;
    pointer-events: none;
    width: 100%;
    z-index: 2;
  }

  // &::before {
  //   background-image: url("../../assets/wave.svg");
  //   background-position: center bottom;
  //   top: 2vh;
  // }

  // &::after {
  //   background-image: url("../../assets/smart-waves.png");
  //   background-position: center;
  // }

  &.sticky {
    &::after {
      background: transparent radial-gradient(closest-side at 25% 50%, #3f97d0 20%, #0f7cc2 150%) 40% 40% no-repeat padding-box;
      border-radius: 0;
      box-shadow: 0 8px 13px #00000030;
      height: 100px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9;
    }

    .logo-column {
      position: fixed;
      top: -8px;

      .a-logo {
        img {
          width: 150px;
        }
      }
    }
  }

  .logo-column {
    z-index: 10;

    .a-logo {
      background-color: $venice-blue;
      border-radius: 0 60px 60px 0;
      display: block;
      position: fixed;
      width: 170px;
    }
  }

  .area-slider {
    @extend %position-absolute;
    @extend %zero-position;
    height: 100%;
    width: 100vw;
    z-index: -1;
    width: 100%;

    &__slide {
      @extend %position-absolute;
      @extend %zero-position;
      height: 100%;
      width: 100%;

      &::before {
        @extend %position-absolute;
        @extend %zero-position;
        @extend %pseudo-el;
        height: 100%;
        opacity: 0.4;
        width: 100%;
      }

      &.blincyto {
        &::before {
          background-color: #38beec;
        }
      }

      img {
        // left: 50%;
        max-width: 100%;
        position: absolute;
        // top: 50%;
        // transform: translate(-50%, -50%);
        width: 110%;
      }
    }
  }

  .columns {
    justify-content: center;
  }

  .m-nav {
    margin-left: auto;
    margin-top: 34px;
  }

  .a-logo-bottom {
    img {
      max-width: 300px;
      width: 64%;
    }
  }

  @include media("<tablet") {
    .logo-column {
      left: 50%;
      position: relative;
      transform: translateX(-50%);
    }
  }

  @include media(">=tablet") {
    background-position: 56% 42%;
    background-size: 150%;
    // height: 30vh;
    padding-top: 0;

    &::before,
    &::after {
      display: block;
    }

    .columns {
      justify-content: start;
    }

    .a-logo {
      display: block;

      img {
        margin-top: 2px;
        width: 110px;
      }
    }

    .logo-bottom-wrapper {
      bottom: 80px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  @media (min-width: 800px) and (min-height: 600px) and (orientation: landscape) {
    // height: 46vh;
  }

  @include media(">=desktop") {
    // height: 56vh;

    &.sticky {
      &::after {
        height: 110px;
      }
    }

    .m-nav {
      margin-top: 30px;
    }

    .logo-bottom-wrapper {
      bottom: 170px;
    }
  }

  @include media(">=widescreen") {
    // height: 48vh;


    .logo-column .a-logo {
      width: 230px;
    }

    .a-logo-bottom {
      img {
        max-width: 220px;
      }
    }
  }

  @include media(">=fullhd") {
    // height: 62vh;

    .a-logo-bottom {
      img {
        max-width: 240px;
      }
    }
  }

  @media screen and (aspect-ratio: 4/3) and (min-width: $desktop) {
    // height: 54vh;
  }

  @media screen and (aspect-ratio: 683/390) and (min-width: $widescreen),
  (aspect-ratio: 16/9) and (min-width: $widescreen) {
    // height: 58vh;
  }
}

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
