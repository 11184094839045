<template>
  <nav class="m-nav">
    <div class="m-nav__desktop-wrapper">
      <AColumns :class="{ 'm-nav__desktop--open': desktopOpen }" class="m-nav__desktop is-gapless" tag="ul">
        <AColumn tag="li">
          <router-link class="is-inline-block pl-3 color4-navs" to="/" title="Home Programa BemPerto"
            style="padding-top: 20px; padding-bottom: 25px;">
            <img src="../../assets/home-bemperto-white.svg" alt="Ícone Home Programa BemPerto" />
            <span class="is-inline-block">
              Home <br />
              BemPerto®
            </span>
          </router-link>
        </AColumn>
        <AColumn tag="li">
          <router-link class="is-inline-block pl-3 color3-navs" to="/sobre-o-programa" title="Saiba mais sobre o programa"
            style="padding-top: 20px; padding-bottom: 25px;">
            <img src="../../assets/heart.svg" alt="Ícone sobre o programa" />
            <span class="is-inline-block">
              Sobre o <br />
              Programa
            </span>
          </router-link>
        </AColumn>
        <AColumn tag="li">
          <a class="is-inline-block pl-3 colors5-navs" href="/sobre-o-programa#participating-programs"
            title="Conheça os programas participantes" style="padding-top: 20px; padding-bottom: 25px;"
            @click="() => goToElement('#participating-programs')">
            <img src="../../assets/checkmark.svg" alt="Ícone programas participantes" />
            <span class="is-inline-block">
              Áreas de <br />
              Atuação
            </span>
          </a>
        </AColumn>
        <AColumn tag="li">
          <router-link class="is-inline-block pl-3 color2-navs" to="/fale-conosco" title="Fale Conosco"
            style="padding-top: 20px; padding-bottom: 25px;">
            <img src="../../assets/phone.svg" alt="Ícone fale conosco" />
            <span class="is-inline-block">
              Fale Conosco
            </span>
          </router-link>
        </AColumn>
        <AColumn tag="li">
          <a class="is-inline-block pl-3 pr-4 color-navs"
            style="width: 170px; border-top-right-radius: 50px; border-bottom-right-radius: 50px; padding-top: 20px; padding-bottom: 25px;"
            href="/#sou-participante" @click="() => goToElement('#sou-participante', '#patient-form')"
            title="Acesse seu perfil">
            <img src="../../assets/enter.svg" alt="Ícone acesse seu perfil" />
            <span class="is-inline-block">
              Acesse seu perfil
            </span>
          </a>
        </AColumn>
      </AColumns>
      <a @click.prevent="toggleDeskMenu" class="m-nav__desktop__action p-2" href=":javascript;" title="Menu">
        <img src="../../assets/hamburger.svg" alt="Ícone menu" />
        <img src="../../assets/blue-close.svg" alt="Ícone fechar menu" />
      </a>
    </div>
    <div :class="{ 'm-nav__mobile--open': mobileOpen }" class="m-nav__mobile">
      <AColumns class="is-centered is-gapless is-mobile is-multiline">
        <AColumn class="has-text-centered is-full">
          <div class="m-nav__mobile__container is-inline-block">
            <router-link to="/" title="Programa BemPerto">
              <ALogo logoType="simple" />
            </router-link>
            <AColumns :class="{ 'is-hidden': !mobileOpen }"
              class="m-nav__mobile__items is-centered is-gapless is-mobile is-multiline mb-2 mt-4" tag="ul">
              <AColumn class="has-text-left is-10-mobile is-8-tablet" tag="li">
                <router-link to="/" title="Home Programa BemPerto">
                  <img src="../../assets/home-bemperto.svg" alt="Ícone Home Programa BemPerto" />
                  Home BemPerto®
                </router-link>
              </AColumn>
              <AColumn class="has-text-left is-10-mobile is-8-tablet" tag="li">
                <router-link to="/sobre-o-programa" title="Toque aqui e saiba mais sobre o Programa BemPerto">
                  <img src="../../assets/half-heart.svg" alt="Ícone coração" />
                  Sobre o programa
                </router-link>
              </AColumn>
              <AColumn class="has-text-left is-10-mobile is-8-tablet" tag="li">
                <a href="#sou-participante" @click="() => goToElement('#sou-participante', '#patient-form')
                  " title="Toque aqui se já for participante do programa">
                  <img src="../../assets/smile.svg" alt="Ícone sorriso" />
                  Sou participante
                </a>
              </AColumn>
              <AColumn class="has-text-left is-10-mobile is-8-tablet" tag="li">
                <a href="#faca-parte" @click="() => goToElement('#faca-parte', '#patient-form')"
                  title="Toque aqui e faça parte do Programa BemPerto">
                  <img src="../../assets/plus.svg" alt="Ícone mais" />
                  Faça parte
                </a>
              </AColumn>
              <AColumn class="is-5-mobile is-4-tablet" tag="li">
                <a :href="`${host}/politica`" title="Toque aqui para ler nossa política de privacidade">
                  Política de privacidade
                </a>
              </AColumn>
              <AColumn class="is-4-mobile is-3-tablet" tag="li">
                <a :href="`${host}/termos-de-uso`" title="Toque aqui para ler nossos termos de uso">
                  Termos de uso
                </a>
              </AColumn>
            </AColumns>

            <div :class="{ 'link-mobile-open': mobileOpen }" class="link-mobile">
                <div tag="div" class="bol">
                  <div class="hedsetIcon-white" alt="Ícone fale conosco"></div>
                  <router-link style="border: none !important; color: white; font-size: 12px !important; text-transform: uppercase !important;" class="textFaleConosco" title="Fale Conosco" to="/fale-conosco">
                   Fale Conosco
                  </router-link>
                </div>
              </div>

            <!-- <MContact :class="{ 'is-hidden': !mobileOpen }" class="has-text-centered mb-5" whiteIcon /> -->
            <a @click.prevent="toggleMobMenu" class="m-nav__mobile__action" href="#" title="Menu">
              <img src="../../assets/close.svg" alt="Ícone fechar" />
              <img src="../../assets/hamburger.svg" alt="Ícone menu" />
            </a>
          </div>
        </AColumn>
        <AColumn class="amgen-logo has-text-centered is-full">
          <img src="../../assets/amgen-bio.svg" alt="Logo Amgen" />
        </AColumn>
      </AColumns>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
import { ref } from "vue";
import AColumn from "@/components/atoms/Column.vue";
import AColumns from "@/components/atoms/Columns.vue";
import ALogo from "@/components/atoms/Logo.vue";
// import MContact from "@/components/molecules/Contact.vue

export default {
  name: "MNav",
  components: {
    AColumn,
    AColumns,
    ALogo,
    // MContact
  },
  setup() {
    const desktopOpen = ref(false);
    const mobileOpen = ref(false);
    const host = `//${document.location.hostname}`;

    function goToElement(id, fallback = false) {
      mobileOpen.value = false;

      let $target = $(id);

      if (!$target.length && fallback) {
        $target = $(fallback);
      }

      if ($target.length) {
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $target.offset().top - 80
          },
          1000
        );
      }
    }

    return {
      desktopOpen,
      goToElement,
      mobileOpen,
      host
    };
  },
  methods: {
    toggleMobMenu() {
      this.mobileOpen = !this.mobileOpen;
    },
    toggleDeskMenu() {
      this.desktopOpen = !this.desktopOpen;
    }
  }
};
</script>

<style lang="scss">
$m-nav: ".m-nav";

#{$m-nav} {
  max-width: 510px;

  &__mobile {
    backface-visibility: hidden;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 9;

    &::before {
      @extend %pseudo-el;
      @extend %zero-position;
      background-color: black;
      display: block;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      width: 100%;
      z-index: -1;
    }

    &::after {
      @extend %position-absolute;
      @extend %pseudo-el;
      @extend %translate-x-center;
      background-color: #1880c3;
      border-radius: 50%;
      bottom: -25vh;
      display: block;
      height: 36vh;
      transition: all 0.3s ease-in;
      width: 38vh;
      z-index: -1;
    }

    .amgen-logo {
      display: none;
    }

    &--open {
      height: 84vh;

      &::before {
        opacity: 0.5;
      }

      &::after {
        background-color: $lochmara;
        bottom: -8vh;
        height: 96vh;
        width: 200%;
      }

      &>.columns {
        height: 100%;
      }

      #{$m-nav}__mobile {
        &__container {
          background-color: transparent;
          border-radius: 0;
          box-shadow: none;
          height: 100%;
          padding: 4vh 0 0;
          text-align: inherit;
          width: 100%;

          img {
            max-width: 40%;
          }
        }

        &__action {
          @extend %translate-x-center;
          background-color: transparent;
          bottom: 42px;
          box-shadow: none;
          height: 30px;
          top: initial;
          width: 30px;

          img {
            &:nth-of-type(1) {
              opacity: 1;
            }

            &:nth-of-type(2) {
              opacity: 0;
            }
          }
        }
      }

      .amgen-logo {
        align-items: flex-end;
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
      }
    }

    &__action {
      box-shadow: 0 6px 12px #00000029;
    }

    &__container {
      border-radius: 60px;
      color: white;
      height: 34px;
      width: 160px;

      img {
        max-width: 60%;
      }

      a {
        display: block;
      }

      .white-circle {
        @extend %white-circle;
        display: inline-block;
        height: 20px;
        width: 20px;
      }

      .m-contact {
        .m-section-title {
          margin-bottom: 0 !important;

          &__title {
            color: $white;
            font-weight: 300;
          }
        }

        &__phone {
          font-size: 1.3rem !important;
          letter-spacing: 4px;
        }
      }
    }

    &__action {
      @extend %position-absolute;
      @extend %white-circle;
      height: 40px;
      left: 50%;
      top: calc(-8vh - 6px);
      transform: translateX(-50%);
      width: 40px;

      img {

        &:nth-of-type(1),
        &:nth-of-type(2) {
          @extend %position-absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
        }

        &:nth-of-type(1) {
          opacity: 0;
        }

        &:nth-of-type(2) {
          opacity: 1;
          width: 38%;
        }
      }
    }

    &__items {
      justify-content: center !important;

      li {
        margin-bottom: 2vh !important;

        &:nth-last-of-type(1) {
          margin-left: 6px !important;
        }

        &:nth-last-of-type(2) {
          margin-right: 6px !important;
        }

        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
          a {
            font-size: pxToEm(11px);
            font-weight: 500;
            height: 26px;
            padding: 4px 0 0;

            &::before {
              display: none;
            }
          }
        }

        &:nth-of-type(1) {
          a {
            img {
              left: 8px;
              margin-top: -2px;
            }
          }
        }

        &:nth-of-type(2) {
          a {
            img {
              left: 8px;
              margin-top: 2px;
            }
          }
        }

        &:nth-of-type(3) {
          a {
            img {
              left: 8px;
            }
          }
        }

        &:nth-of-type(4) {
          a {
            img {
              left: 8px;
            }
          }
        }
      }

      a {
        background-color: transparent;
        border: 2px solid $white;
        border-radius: 60px;
        color: $white;
        font-size: pxToEm(12px);
        font-weight: 600;
        height: 40px;
        padding: 0.6rem 0 0 4em;
        position: relative;

        &::before {
          @extend %pseudo-el;
          @extend %position-absolute;
          @extend %white-circle;
          box-shadow: 0 6px 12px #00000048;
          display: block;
          height: 40px;
          left: -1px;
          top: -2px;
          width: 40px;
        }

        img {
          @extend %position-absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);
          max-width: 7% !important;
        }
      }
    }
  }

  &__desktop {
    &-wrapper {
      display: none;
      position: relative;
    }

    &__action {
      @extend %position-absolute;
      background-color: $white;
      border-radius: 60px 0 0 60px;
      right: 200px !important; 
    top: 10px !important;
      width: 60px;

      img {
        margin-left: 4px;
        margin-top: 4px;

        &:nth-last-of-type(1) {
          display: none;
        }
      }
    }

    li {
      @extend %position-absolute;
      right: 16px;

      &:nth-of-type(1) {

        a,
        a::before {
          background-color: #4baae6;
        }
      }

      &:nth-of-type(2) {

        a,
        a::before {
          background-color: $curious-blue;
        }
      }

      &:nth-of-type(3) {

        a,
        a::before {
          background-color: $denim;
        }
      }

      &:nth-of-type(4) {

        a,
        a::before {
          background-color: $bahama-blue;
        }
      }

      &:nth-of-type(5) {
        a {
          // background: transparent radial-gradient(closest-side at 41% -11%, #4baae6 0%, #298ece 100%) 0% 0% no-repeat padding-box;
          border-radius: 0 60px 60px 0;
          width: auto;

          &::before {
            background-color: #298ece;
          }

          &::after {
            @extend %position-absolute;
            @extend %pseudo-el;
            border-radius: 60px;
            box-shadow: 0px 12px 12px #00000031;
            height: 100%;
            left: -24px;
            top: 0;
            width: calc(100% + 24px);
            z-index: -2;
          }

          img {
            margin-left: -18px;
          }
        }
      }

      &:not(:nth-last-of-type(1)) {
        right: 30px;

        a {
          border-radius: 0 60px 60px 0;
          font-size: 0;

          &::before {
            left: -40px;
          }
        }
      }

      &:nth-last-of-type(1) {
        z-index: 4;
      }

      a {
        color: $white;
        font-family: "Helvetica Neue Bold";
        font-size: pxToEm(11px);
        line-height: pxToEm(18px);
        max-height: 46px;
        min-height: 46px;
        position: relative;
        text-decoration: none;
        width: 100%;
        // box-shadow: 0px 12px 12px #00000031 !important;

        &::before {
          @extend %position-absolute;
          @extend %pseudo-el;
          border-radius: 60px 0 0 60px;
          height: 100%;
          left: -24px;
          top: 0;
          width: 34px;
          z-index: 2;
        }

        img,
        span {
          vertical-align: middle;
        }

        img {
          height: 18px;
          margin-left: -12px;
          margin-right: 6px;
          position: relative;
          z-index: 3;
        }
      }
    }

    &--open {
      &+#{$m-nav} {
        &__desktop {
          &__action { //ABERTO
            left: -65px;
            right: 0;
            top: 12px !important;

            img {
              &:nth-of-type(1) {
                display: none;
              }

              &:nth-last-of-type(1) {
                display: inline;
              }
            }
          }
        }
      }

      li {
        right: 0;
        position: relative;

        &:not(:nth-last-of-type(1)) {
          right: 0;

          a {
            border-radius: 0;
            font-size: pxToEm(12px);

            &::before {
              left: -24px;
            }
          }
        }
      }
    }

    @include media(">=desktop") {
      &__action {
        right: 158px;
        top: 5px;
      }

      li {
        &:nth-of-type(4) {
          a {
            &::after {
              left: -30px;
              width: calc(100% + 30px);
            }
          }
        }

        a {
          font-size: pxToEm(12px);
          max-height: 62px;
          min-height: 50px;

          &::before {
            left: -30px;
          }

          img {
            height: 24px;
            margin-left: -10px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  @include media(">=tablet") {
    &__mobile {
      &__items {
        li {
          margin-bottom: 3vh !important;
        }

        a {
          height: 60px;
          font-size: pxToEm(14px);
          padding: 1.25rem 0 0 5rem;

          &::before {
            height: 60px;
            width: 60px;
          }
        }
      }
    }
  }

  @include media(">=desktop") {
    max-width: 760px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &__mobile {
      display: none;
    }

    &__desktop {
      &-wrapper {
        display: block;
      }
    }
  }

  .color-navs {
    background-color: #1C8DAA !important;

    &::before {
      background-color: #1C8DAA !important;
    }
  }

  .color2-navs {
    background-color: #2D9CB9 !important;

    &::before {
      background-color: #2D9CB9 !important;
    }
  }

  .color3-navs {
    background-color: #4FAEC7 !important;

    &::before {
      background-color: #4FAEC7 !important;
    }
  }

  .color4-navs {
    background-color: #65C2DB !important;

    &::before {
      background-color: #65C2DB !important;
    }
  }

  .colors5-navs {
    background-color: #53a2b7 !important;

    &::before {
      background-color: #53a2b7 !important;
    }
  }

  .bol {
      a {
      &::before {
        width: 0px;
        
      }
    }
  }
  
  .link-mobile{
    display: none !important;
  }

  .link-mobile-open {
    display: contents !important;
  }
}
</style>
