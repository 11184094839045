<template>
  <div class="a-password-input a-input">
    <label class="a-input__label" :for="id">
      <input
        :type="fieldType"
        :class="{ 'is-filled': value, 'is-invalid': error }"
        :name="name"
        :id="id"
        :required="required"
        @blur="onBlur"
        @input="onInput"
        v-model="value"
      />
      <span class="a-input__label__text">{{ label }}</span>
      <span v-if="error && typeof error === 'string'" class="a-input__error">{{
        error
      }}</span>
      <template v-if="value">
        <img
          v-if="fieldType === defaultType"
          class="a-password-input__toggle-view"
          src="../../assets/eye.svg"
          alt="Mostrar/Esconder senha"
          @click="toggleFieldType"
        />
        <img
          v-else
          class="a-password-input__toggle-view"
          src="../../assets/hide.svg"
          alt="Mostrar/Esconder senha"
          @click="toggleFieldType"
        />
      </template>
    </label>
  </div>
</template>

<script>
import { ref } from "vue";
import InputMixin from "@/mixins/InputMixin";

export default {
  name: "APasswordInput",
  mixins: [InputMixin],
  setup() {
    const defaultType = "password";
    const fieldType = ref(defaultType);

    function toggleFieldType() {
      fieldType.value = fieldType.value === defaultType ? "text" : defaultType;
    }

    return {
      defaultType,
      fieldType,
      toggleFieldType
    };
  }
};
</script>

<style lang="scss">
.a-password-input {
  &__toggle-view {
    @extend %position-absolute;
    cursor: pointer;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
  }
}
</style>
