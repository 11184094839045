import { createApp } from "vue";
import App from "./App.vue";
import VueCookies from "vue3-cookies";
import router from "./router";
import store from "./store";
import Maska from "maska";
import { VuelidatePlugin } from "@vuelidate/core";
import "./registerServiceWorker";

const app = createApp(App)
  .use(VueCookies, {
    expireTimes: "120d"
  })
  .use(store)
  .use(Maska)
  .use(VuelidatePlugin)
  .use(router);

app.mount("#app");
